import Documents from "@/components/widgets/documents";
import DepositDocuments from "@/components/widgets/depositdocuments";
import Seller from "@/components/widgets/seller";
import DatePicker from "vue2-datepicker";
import Loading from "@/components/widgets/Loading.vue";

export default {
  name: "Bill-Document",
  components: {
    Documents,
    Seller,
    DatePicker,
    DepositDocuments,
    Loading
  },
  props: {
    documentItem: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      selectedSeller: {},
      selectedCustomer: {},
      documentEnabled: false,
      depositDocumentEnabled: false,
      isSellerEnabled: false,
      bank_account_list: [],
      formData: {
        bill_payment_method: null,
        bill_duedate: null,
        bill_shipping_method: null,
        bill_shipping_date: null,
        bill_quotation_no: "",
        bill_delivery_date: new Date(),
        bill_manipulator: "",
        bill_seller: "",
        bill_seller_id: "",
        bill_reference: "",
        bill_deposit_type: 0,
        bill_deposit_amt: "",
        deposit_document_id: null,
        deposit_document_no: "",
        bill_credit_detail: null,
        bank_account_detail: null,
      },
    };
  },
  mounted() {
    this.getRoleOfUser();
  },
  watch: {
    "formData.bill_payment_method"(new_val) {
      this.onLoadBankAccount();
    },
    documentItem: {
      handler(newValue, oldValue) {
        this.formData = newValue;
      },
      deep: true,
    },
    formData: {
      handler(newValue, oldValue) {
        this.$emit("documentData", {
          document: newValue,
        });
      },
      deep: true,
    },
  },
  methods: {
    async onLoadBankAccount() {
      try {
        this.loading = true;
        const res = await this.$axios.get(
          this.$host +
          "/api/v1/document/quotation/branch/bank_accounts/type/" +
          this.formData.bill_payment_method
        );

        this.bank_account_list = res.data;
        this.loading = false;

      } catch (error) {
        this.loading = false;

        this.onExceptionHandler(error.response.data.message);
      }
    },
    async getRoleOfUser() {
      const res = await this.$store.dispatch(
        "Auth/getRole",
        {},
        { root: true }
      );
      if (res.status === 200) {
        if (!this.formData.bill_manipulator) {
          this.formData.bill_manipulator = res.data.name;
        }
        if (!this.$route.query.q) {
          this.formData.bill_seller = res.data.name;
          this.formData.bill_seller_id = res.data.id;
        }
        else{
          const res = await this.$axios.get(
            this.$host + "/api/v1/document/quotation/" + this.$route.query.q,
            {
              headers: {},
            }
          );
          this.formData.bill_payment_method = res.data.payment_type
          this.formData.bank_account_detail = res.data.bank_account_detail
          this.formData.bill_credit_detail = res.data.credit_detail
        }
      }
    },
    onOpenSellerPopup() {
      this.isSellerEnabled = !this.isSellerEnabled;
    },
    /** เปิด dialog ค้นหาข้อมูลใบส่งสินค้า */
    onOpenDocumentHandler() {
      this.documentEnabled = !this.documentEnabled;
    },
    onOpenDepositHandler() {
      this.depositDocumentEnabled = !this.depositDocumentEnabled;
    },
    /** เพิ่มข้อมูลใบเสนอ */
    onInitDepositDocumentSettings(e) {
      this.depositDocumentEnabled = false;
      if (e.status) {
        const data = e.data;
        this.formData.bill_deposit_amt = data.amount;
        this.formData.deposit_document_id = data.id;
        this.formData.deposit_document_no = data.document_no;
      }
    },
    /** เพิ่มข้อมูลใบมัดจำ */
    onInitDocumentSettings(e) {
      this.documentEnabled = false;
      if (e.status) {
        const data = e.data;
        this.formData.bill_quotation_no = data.quotation_no;
        this.formData.bill_quotation_id = data.id;
      }
    },
    onSetSeller(e) {
      this.isSellerEnabled = false;
      const seller_name = e.data.name;
      this.formData.bill_seller = seller_name;
      this.formData.bill_seller_id = e.data.id;
    },
    onclear() {
      this.formData.bill_payment_method = null;
      this.formData.bill_duedate = null;
      this.formData.bill_shipping_method = null;
      this.formData.bill_shipping_date = null;
      this.formData.bill_quotation_no = "";
      this.formData.bill_delivery_date = null;
      this.formData.bill_manipulator = "";
      this.formData.bill_seller = "";
      this.formData.bill_id = "";
      this.formData.bill_reference = "";
      this.formData.deposit_document_id = null,
      this.formData.deposit_document_no = "",
      this.formData.bill_credit_detail = null
      this.formData.bank_account_detail = null
    },
  },
};
